@font-face {
  font-family: campton;
  src: url(./assets/font/campton-cufonfonts/CamptonLight.otf);
}
@font-face {
  font-family: catamaran;
  src: url(./assets/font/catamaran/catamaran.regular.ttf);
}
.smartNew-main {
  background-color: #001B3D;
}
.smartNew-main .header {
  height: 120px;
  /* position: fixed; */
  backdrop-filter: blur(12px);
}
a {
  text-decoration: none;
  background-color: transparent;
}
.header {
  /* position: absolute; */
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 90px;
  padding: 0 10px;
  border-bottom: 1px solid transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: border-bottom 0.4s, background-color 0.4s;
  -o-transition: border-bottom 0.4s, background-color 0.4s;
  transition: border-bottom 0.4s, background-color 0.4s;
}
.logo__img {
  /* width: 45px;
  height: 51px; */
  height: 74px;
  width: 235px;
  /* background-image: url(../img/logo.png); */
  background-image: url(./assets/images/logo.png);
  -webkit-background-size: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 13px;
  -webkit-transition: background-image 0.4s;
  -o-transition: background-image 0.4s;
  transition: background-image 0.4s;
}
.logo__img2 {
  /* width: 45px;
  height: 51px; */
  height: 74px;
  width: 130px;
  background-image: url(./assets/images/logo.png);
  /* background-image: url(../img/logoGif.gif); */
  -webkit-background-size: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 13px;
  -webkit-transition: background-image 0.4s;
  -o-transition: background-image 0.4s;
  transition: background-image 0.4s;
}
.menu {
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu__item {
  margin-right: 20px;
}

.menu__item:last-child {
  margin-right: 0;
}

.nav__con {
  display: flex;
  justify-content: space-between;
}

.menu__link {
  color: white;
  position: relative;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  white-space: nowrap;

}

.menu__link:after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #fff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

.menu__link:hover {
  color: #fff;
}

.menu__link:hover:after {
  opacity: 1;
}
.logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  top: -3.5px;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.header__right {
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 80px;
}


.smartNew-main .header.sticky  {
  height: 120px;
  backdrop-filter: blur(11px);
  background-color: transparent;
  border-bottom: 1px solid transparent;
} 
/* .smartNew-main .header.sticky .menu__link:hover{
  color: #777777 !important;
}
.smartNew-main .header.sticky .menu__link:hover{
  color: #777777 !important;
} */
.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 11;
  height: 90px;
  border-bottom: 1px solid #f5f5f5;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  padding: 0 10px;
}

.header.sticky .logo__title {
  color: #777777;
}

.header.sticky .logo__img2 {
  background-image: url(./assets//images/logo.png);
  /* background-image: url(../img/logoGif.gif); */
  height: 75px;
  width: 220px;
}

.header.sticky .menu__link {
  color: #777777;
}

.header.sticky .menu__link:after {
  border-bottom: 2px solid #fff;
}

.header.sticky .select-styled {
  color: #777777;
}

.header.sticky .btn-sign-up {
  color: #777777;
}

.header.sticky .btn-sign-up:hover {
  color: #557ef8;
}


.header.sticky .btn-sign-in {
  color: white;
  background-color: #557ef8;
  cursor: pointer;
}


.header.sticky .btn-sign-in:hover {
  color: white;
  background-color: #616366;
}

.header.sticky .btn-menu div {
  background-color: rgb(194, 194, 194);
}

.header.sticky .btn-menu div.one:after,
.header.sticky .btn-menu div.three:after {
  background-color: rgb(194, 194, 194);
}
.smartNew-main .btn-sign-in {
  color: #17447E;
}
.btn-sign-in:disabled {
  cursor: not-allowed;
}
.btn-sign-in {
  min-width: 180px;
  height: 50px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  /* background-color: #557ef8; */
  background-color: white;
  line-height: 50px;
  /* color: #ffffff; */
  color: #17447E;
  font-size: 17px;
  text-align: center;
  display: block;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  white-space: nowrap;
  font-weight: bolder;
  border: none;
  cursor: pointer;
}

.btn-sign-in:hover {
  /* color: #fff;*/
  color: #ffffff;
  /* background-color: #2ddfd1; */
  background-color: #05B4EF;
}
.header.sticky .btn-menu div {
  background-color: rgb(194, 194, 194);
}

.header.sticky .btn-menu div.one:after,
.header.sticky .btn-menu div.three:after {
  background-color: rgb(194, 194, 194);
}
.btn-menu {
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  text-align: right;
  margin-left: 25px;
  display: block;
}

.btn-menu div {
  width: 32px;
  height: 3px;
  min-height: 3px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  margin-bottom: 6px;
  -webkit-border-radius: 1.5px;
  border-radius: 1.5px;
  position: relative;
  background-color: hsl(0, 0%, 100%);
}

.btn-menu div:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 1.5px;
  border-radius: 1.5px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.btn-menu div:last-child {
  margin-bottom: 0;
}

.btn-menu div:last-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 1.5px;
  border-radius: 1.5px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.btn-menu.on {
  top: -5px;
}

.btn-menu.on .one {
  -webkit-transform: rotate(45deg) translate(6px, 2px);
  -ms-transform: rotate(45deg) translate(6px, 2px);
  transform: rotate(45deg) translate(6px, 2px);
  margin-bottom: 0;
}

.btn-menu.on .one:after {
  width: 100%;
  background-color: #777777;
}

.btn-menu.on .two {
  opacity: 0;
  margin-bottom: 0;
}

.btn-menu.on .three {
  -webkit-transform: rotate(-45deg) translate(2px, 2px);
  -ms-transform: rotate(-45deg) translate(2px, 2px);
  transform: rotate(-45deg) translate(2px, 2px);
  width: 32px;
  margin-bottom: 0;
}

.btn-menu.on .three:after {
  width: 100%;
  background-color: #777777;
}
.fixed-menu .btn-menu div {
  background-color: #777777;
}

.fixed-menu .btn-menu div.one:after,
.fixed-menu .btn-menu div.three:after {
  background-color: #777777;
}

.fixed-menu {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 12;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0 10px;
}

.fixed-menu.open {
  visibility: visible;
  display: block;
  opacity: 1;
}

.fixed-menu__header {
  width: 100%;
  height: 90px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.fixed-menu__content {
  padding: 20px 90px 20px;
}

.fixed-menu .select-styled.active {
  color: black;
}

.fixed-menu .select-options {
  right: -15px;
  top: 45px;
}

.fixed-menu .select-styled,
.fixed-menu .btn-sign-up {
  color: #777777;
}

.fixed-menu .select-styled:hover {
  color: black;
}

.fixed-menu .select {
  margin-bottom: 30px;
}

.fixed-menu .btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.fixed-menu .btn-menu div {
  background-color: #777777;
}

.fixed-menu .btn-menu div.one:after,
.fixed-menu .btn-menu div.three:after {
  background-color: #777777;
}

.mob-menu {
  margin-bottom: 40px;
  list-style: none;
}

.mob-menu__item {
  text-align: left;
  margin-bottom: 10px;
}

.mob-menu__item:last-child {
  margin-bottom: 0;
}

.mob-menu__link {
  color: #777777;
  position: relative;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  white-space: nowrap;
}

.mob-menu__link:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #00c3e2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

.mob-menu__link:hover {
  color: #777777;
}

.mob-menu__link:hover:after {
  opacity: 1;
}

.topupForm .input::placeholder {
  color: white;
}
.topupForm .input:disabled::placeholder {
  color: rgba(255,255,255,0.5);
}
.topupForm .input:disabled {
  /* color: rgba(255,255,255,0.5); */
  opacity: 0.6;
}
.topupForm .input.disabled {
  color: rgba(255,255,255,0.5);
  opacity: 0.6;
}
.topupForm .input {
  font-family: catamaran;
  background: #1A3251;
  padding: 13px 25px;
  border-radius: 12px;
  border: none;
  width: 100%;
  margin: 8px 0;
  color: #fff;
  text-align: left;
}

.topupForm .input:hover {
  background: #1A3251;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(11 158 220 / 25%);
  color: white;
}

.topupForm .submitBtn {
  background: #05B4EF;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 9px 25px;
  border: none;
  border-radius: 8px;
  width: 170px;
}

.topupForm .submitBtn:hover {
  background: #fff;
  color: #05B4EF;
}
.topupForm .selectMain {
  position: relative;
}
.topupForm .selectMain .ico{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 25px;
}
.topupForm
select::-ms-expand {
  display: none;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
} 
.topupSection {
  padding: 60px 0;
  min-height: calc(100vh - 120px) ;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
}
.topupSection .topupForm {
  max-width: 800px;
  margin: auto;
  padding: 0 10px ;
}
.topupSection h1 {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: campton;
  font-weight: 600;
}
.topupSection .desc {
  font-size: 23px;
  margin-bottom: 25px;
  /* font-weight: 500; */
}
.topupSection .estimate {
  margin-bottom: 20px;
}
.topupSection .estimate p{
  font-size:18px;
}
.topupSection .estimate p span{
  font-size: 60px;
}
.topupSection .sendBtn  {
 display: flex;
 align-items: center;
 justify-content: center;
}
.topupSection .sendBtn .button {
  min-width: 180px;
  white-space: normal;
  min-height: 50px;
  height: auto;
  line-height: 1.3;
  padding: 7px;
  border:  2px solid #fff;
}
.topupSection .sendBtn .button1 {
  max-width: 80px;
  min-width: 160px;
  white-space: normal;
  min-height: 50px;
  height: auto;
  line-height: 1.3;
  padding: 7px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  display: flex;align-items: center;
  justify-content: center;
}
.topupSection .sendBtn .button1:hover {
  color: #17447E;
  border: 2px solid #fff;
  background: white;

}
.ai-center {
  align-items: center;
}
.topupSection .formCol {
  position: relative;
}
/* .topupSection .formCol::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: #1A3251;
  right: 20px;
}  */

.qrModal {
  padding: 15px;
  background: #001B3D;
  color: white;
  text-align: center;
}
.qrModal .timer span{
 display: inline-block;
 width: 82px;
}
.qrModal .timer {
  font-size: 50px;
  margin-bottom: 10px;
  font-family: campton;
  font-weight: bold;
}
.qrModal .price span{
 display: inline-block;
 width: 82px;
}
.qrModal .price {
  font-size: 40px;
  margin-bottom: 10px;
  /* font-family: campton; */
  font-weight: bold;
}
.topupSection .receptMain {
  font-family: catamaran;
}
.topupSection .receptMain .rCard .detailMain .dRow .statusLabel {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  padding: 3px 6px;
}
.topupSection .receptMain .rCard .detailMain .dRow .expired {
  background-color: #dc3545;
  border-color: #dc3545;
}
.topupSection .receptMain .rCard .detailMain .dRow .executed {
  background-color: #28a745;
  border-color: #28a745;
}
.topupSection .receptMain .rCard .detailMain .dRow .received {
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.topupSection .receptMain .rCard .detailMain .dRow .pending {
  background-color: #ffc107;
  border-color: #ffc107;
}
.qrModal .qrCard {
  padding: 20px;
  background: #fff;
  border-radius: 22px;
  /* margin-bottom: 20px; */
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.jc-sb {
  justify-content: space-between;
}
.qrModal .qrCard h5{
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.qrModal .addCard {
  background: rgba(255,255,255,0.1);
  padding: 15px 20px;
  border-radius: 12px;
  width: max-content;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 15px;
}
.qrModal .addCard .address .imgDiv {
  position: relative;
}
.qrModal .addCard .address .imgDiv:hover .copyText {
  display: block;
}
.qrModal .addCard .address .imgDiv .copyText {
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  top: 30px;
  background: black;
  padding: 7px;
  border-radius: 7px;
  width: max-content;
  display: none;
}
.qrModal .addCard .address img {
  width: 20px;
  margin-left: 10px;
  cursor: copy;
}
.qrModal .addCard .address {
  font-size: 13px;
  /* margin-top: 10px; */
  color: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.topupSection .receptMain .rCard .detailMain .address .imgDiv {
  position: relative;
}
.topupSection .receptMain .rCard .detailMain .address .imgDiv:hover .copyText {
  display: block;
}
.topupSection .receptMain .rCard .detailMain .address .imgDiv .copyText {
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  top: 30px;
  background: black;
  filter: invert(1);
  color: white;
  padding: 7px;
  border-radius: 7px;
  width: max-content;
  display: none;
}
.topupSection .receptMain .rCard .detailMain .address img {
  width: 20px;
  margin-left: 10px;
  cursor: copy;
  filter: invert(1);
}
.topupSection .receptMain .rCard .detailMain .address {
  font-size: 13px;
  /* margin-top: 10px; */
  /* color: rgba(255,255,255,0.8); */
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrModal .executedMain {
  font-size: 46px;
  font-weight: bold;
  font-family: 'campton';
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrModal .executedMain img{
  width: 30px;
  margin-right: 10px;
}
.blurFg {
  filter: blur(8px);
}
.qrModal .text{
  margin-top: 15px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
}
.modal-content {
  border-radius: 25px !important;
  overflow: hidden !important;
}
.modal-dialog {
  max-width: 600px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.topupForm .dropdown-toggle::after{
  display: none;
}
.topupForm .dropdown-menu {
  width: 100%;
  background-color: #1A3251;
  color: white;
}
.topupForm .dropdown-item {
  color: white;
}

.topupForm .selectMain .Icoimg {
  width: 50px;
  margin-right: 10px;
}
.topupForm .selectMain .Icoimg img{
  max-width: 100%;
}
.topupForm .dropdown-item  {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.topupForm .dropdownamount {
  font-size: 13px;
}
.topupForm .dropdowndetail {
  font-size: 13px;
  white-space: normal;
  margin-right: 20px;
}
.topupForm .helpIco {
  position: relative;
}
.topupForm .amountPackage {
  display: flex;
  width: 100%;
}
.topupForm .amountPackage input{
  margin-right: 15px;
}
.topupForm .amountPackage .selectMain{
  margin-left: 15px;
}
.topupForm .helpIco img{
  width: 43px;
  cursor: help;
}
.topupForm .helpIco:hover .text{
  display: block;
}
.topupForm .helpIco .text{
  display: none;
  font-size: 12px;
  position: absolute;
  transform: translate(-50%,0);
  left: 50%;
  min-width: 150px;
  z-index: 10;
  padding: 8px;
  border-radius: 10px;
  bottom: 54px;
  background: #1A3251;
  width: max-content;
  max-width: 275px;
}
.topupForm .downMM {
  max-height: 200px;
  overflow-y: auto;
}
.topupForm .dropdownamount span{
  color:  rgba(255,255,255,0.7);
}
.topupForm .dropdowndetail span{
  color:  rgba(255,255,255,0.7);
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #1f416e;
}
.topupForm .networkDrop {
  display: flex;
  align-items: center;
  /* max-height: 50px; */
}
.topupForm .easyLoadBtn {
  margin-right: 67px;
  display: flex;
  padding: 0;
  width: 100%;
}
.topupForm .easyLoadBtn .button img{
  width: 23px;
  margin-right: 20px;
  margin-top: 2px;
}
.topupForm .easyLoadBtn .button.active{
  /* background: #00ca07; */
  border-radius: 12px ;
  color: rgba(255,255,255,1);
  font-weight: 500;
}
.topupForm .easyLoadBtn .button{
  font-family: catamaran;
  color: rgba(255,255,255,0.5);
  width: 100%;
  height: 50px;
  line-height: 41px;
  padding: 6px 15px;
  display: flex;align-items: center;
  cursor: pointer;
}
.jc-center {
  justify-content: center;
}
.topupSection .receptMain .rCard .dateNow {
  color: black;
  font-size: 13px;
  filter: invert(1);
}
.topupSection .receptMain .rCard {
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 15px;
  background: rgba(255,255,255,0.08);
  color: black;
  position: relative;
}
.topupSection .receptMain .rCard .priceMain .title{
  font-size: 20px;
}
.topupSection .receptMain .rCard .priceMain .tup {
  font-size: 45px;
  line-height: 1;
  font-family: campton;
  font-weight: bold;
}
.topupSection .receptMain .rCard .detailMain .heading {
  font-size: 28px;
  font-weight: 500;
  filter: invert(1);
}
.topupSection .receptMain .rCard .detailMain .dRow:last-child {
  border: none;
}
.topupSection .receptMain .rCard .priceMain {
  filter: invert(1);
}
.border-none {
  border: none !important;
}
.topupSection .receptMain .rCard .detailMain .dRow {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  border-bottom: 2px solid rgba(255,255,255,0.1);
}
.topupSection .receptMain .rCard .detailMain .dRow .key{
  filter: invert(1);
}
.topupSection .receptMain .rCard .detailMain .dRow .val{
  font-size: 18px;
  font-weight: bold;
  filter: invert(1);
}
.topupSection .receptMain .rCard .detailMain .detail{
  text-align: left;
  filter: invert(1);
}
.succesModal {
  background: #001B3D !important;
  padding: 35px 10px;
}
.succesModal .successIco {
  margin-bottom: 15px;
}
.succesModal .successIco img {
  width: 110px;
}
.qrModalMain {
  backdrop-filter: blur(6px) !important;
    background: rgb(102 209 245 / 20%) !important;
}

@media (min-width: 1200px) {
  .header__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .btn-menu {
    display: none;
  }

  .logo__title {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .topupForm .amountPackage {
    flex-direction: column;
  }
  .topupForm .amountPackage input {
    margin-right: 0;
  }
  .topupForm .amountPackage .selectMain {
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .topupForm .helpIco {
    display: none;
  }
}
@media (min-width: 768px) {
  .header {
    padding: 0 40px;
  }

  .header.sticky {
    padding: 0 40px;
  }

  .fixed-menu {
    padding: 0 40px;
  }
}
